import { Box, Grid, Input, Stack, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { InvItem } from "./invItem";
import "./inv.css";
import { ItemDetails } from "./itemDetails";
import { useState } from "react";
import { ics } from "../utils/utils";
import { UiControls } from "./uicontrols";
import { coinHighscoresApi } from "../apis/coin-highscores";
import useAxios from "../hooks/useAxios";

export const Inv = (itemList, quantity) => {
  if (!itemList) {
    itemList = [];
  }
  // console.log(itemList.itemList);
  var j = itemList.itemList;
  // console.log(j);

  var view = itemList.view

  var [iteminfo, setIteminfo] = useState({
    name: "xxxxxxxxx",
    quantity: "",
    priceEach: "",
    priceTotal: "",
  });

  var [ssearch, setSsearch] = useState('')

  //FILTERING J

  if(ssearch !== ''){
    var theNew = []
    for(var d = 0; d < j.length; d++){
      if(j[d][0].includes(ssearch.toLowerCase())){
        theNew.push(j[d])
      }
    }

    j = theNew
  }

  const [playerDataRetrieved, error, loadings, axiosFetch] = useAxios();

  const getData = () => {
    axiosFetch({
      axiosInstance: coinHighscoresApi,
      method: "get",
    });
  };


 

  var hoverCallBack = function (inputs) {
    // console.log("in inv, hoverCallBack");
    // console.log(inputs);
    setIteminfo(inputs);
  };

  var handleSearchChange = (event) => {

      //  setUsernamey(event.target.value);
      setSsearch(event.target.value)
  }

  var hoverEndCallBack = function () {
    // console.log("hoverEndCallBack");
    setIteminfo({
      name: "xxxxxxxxx",
      quantity: "",
      priceEach: "",
      priceTotal: "",
    });
  };

  var [invState, setInvState] = useState('open');

  var handleExpandInv = (whatIs) => {
    console.log('inv handleExpand ' + whatIs)
    setInvState(whatIs)
  }

  var handleRefreshInv = () => {
    console.log('handleRefresh')
    //getData()
  }


  if(invState == 'closed'){
    return (
      <Box className="inv">
        <Box className="invTitleHolder">
          <Stack>
          <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
            <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
              Inventory
            </Typography>
            <UiControls hideRefresh={true}  startState={invState} expand={handleExpandInv} refresh={handleRefreshInv}/>
            </Grid>
          </Stack>
        </Box>
        </Box>
        )

  }


  if (view == 0) {
    return (
      <Box className="inv">
        <Box className="invTitleHolder">
          <Stack>
          <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
            <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
              Inventory
            </Typography>
            <UiControls hideRefresh={true} startState={invState} expand={handleExpandInv} refresh={handleRefreshInv}/>
            </Grid>
            <ItemDetails inputVars={iteminfo} totalVals={itemList.totals} />
            <Input
                type="text"
                id="inputSpyName"
                className="inputSpyName"
                defaultValue={''}
                onChange={handleSearchChange}
                // value={usernamey}
              />
          </Stack>
        </Box>
        <Grid container spacing={{ xs: 0, md: 0 }}>
          {j.map((_, index) => {
            // console.log(_);
            return (
              <Box padding={"6px"}>
                <InvItem
                  name={_[0].toString()}
                  quantity={_[1]}
                  price={_[2]}
                  hoverCallBack={hoverCallBack}
                  hoverEndCallBack={hoverEndCallBack}
                />
              </Box>
            );
          })}
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box className="inv">
        <Box className="invTitleHolder">
          <Stack>
            <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
            <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
              Inventory
            </Typography>
            <UiControls hideRefresh={true} startState={invState} expand={handleExpandInv} refresh={handleRefreshInv}/>
            </Grid>
            <Input
                type="text"
                id="inputSpyName"
                className="inputSpyName"
                defaultValue={''}
                onChange={handleSearchChange}
                // value={usernamey}
              />
          </Stack>
        </Box>
        <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
          {j.map((_, index) => {
            // console.log(_);
            return (
              <Grid xs={4} className={'spanRow'}>

                <Box padding={"8px"}>
                  {"" + _[1] + "x " + _[0].toString() + ""}
                </Box>
  
              </Grid>
            );
          })}
        </Grid>
      </Box>
    )
  }
};


