import { Box, Grid } from "@mui/material";
import { textAlign } from "@mui/system";
import "./sellingListing.css";

export const SellingListing = (incomingObj) => {
 
    var itemName = incomingObj.itemName
    var  stock = incomingObj.stock
     var sellerUsername = incomingObj.sellerUsername
     var priceEach = incomingObj.priceEach
    var  itemQuality = incomingObj.itemQuality || "white"


    priceEach = priceEach.slice(1)
    var itemColorClassName = "quality_"+itemQuality

    var priceColor = "white"

    var eachString = ""


    var spruce = ""

    if(stock > 1){
      eachString += " each"
    }

    if(priceEach.includes('k')){
      priceColor = "yellow"
    }

    if(priceEach.includes('m')){
      priceColor = "green"
    }
    if(priceEach.includes('b')){
      priceColor = "pink"
    }


  //var quantString = "";
  return (
    <Box
      className="aListingContainer"
    >
    <Box className="leftSideContainer" >
        <Box className="anItemBox1">
        <img src={"/itembackdrop.png"} />
      </Box>
      <Box className="anItemBox1">
        <img src={"/items/" + itemName + ".png"} />
      </Box>
      <Box className="quantityStrip">
          <div className="stockLine">{'x'+stock}</div> 
      </Box>
    </Box>

        <Box className="rightSideContainer" >
          <Box className="rightSideOffset">
            <Box className="usernameStrip strip">
            <img src="/icon_player1.png" /> {sellerUsername}
          </Box>
          <Box className={"itemnameStrip "+itemColorClassName + " strip"}>
          <img src="/icon_bag1.png" />  <p>{itemName} </p>
          </Box>
          <Box className="priceStrip strip">
          <img src="/icon_money1.png" />  <div className={'priceColor_'+priceColor}> {priceEach}</div> <div className={'eachString'}>{eachString}</div>
          </Box>
            </Box>
            </Box>
    </Box>
  );
};
