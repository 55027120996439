import { textAlign } from "@mui/system";

import {
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Input,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import { coinHighscoresApi } from "../apis/coin-highscores";
import { playerLookupApi } from "../apis/player-lookup";
import { TableComponent } from "../components/table";
import { captureRejectionSymbol } from "events";
import ReactSkillbar from "react-skillbars";
import { Rings } from "react-loader-spinner";
import ProgressBar from "@ramonak/react-progress-bar";
import { Inv } from "../components/inv";

import "./inv.css";
import { BiBlanket } from "react-icons/bi";

export const ItemDetails = (inputVars) => {
  var ics = function (rawCoinValue, removeIRM) {
    var stringToDisplay = "$IRM ";
    if (removeIRM === true) {
      stringToDisplay = "";
    }
    var extraDisplayRule = "NONE";

    var displayDecimalAsString = null;

    if (rawCoinValue > 1000000000000) {
      displayDecimalAsString = parseFloat(
        ((1.0 * rawCoinValue) / 1000000000000).toFixed(3)
      );
      return stringToDisplay + displayDecimalAsString + "t";
    }

    if (rawCoinValue > 1000000000) {
      displayDecimalAsString = parseFloat(
        ((1.0 * rawCoinValue) / 1000000000).toFixed(3)
      );
      return stringToDisplay + displayDecimalAsString + "b";
    }
    if (rawCoinValue > 1000000) {
      displayDecimalAsString = parseFloat(
        ((1.0 * rawCoinValue) / 1000000).toFixed(2)
      );
      return stringToDisplay + displayDecimalAsString + "m";
    }
    if (rawCoinValue > 10000) {
      displayDecimalAsString = parseFloat(
        ((1.0 * rawCoinValue) / 1000).toFixed(1)
      );
      return stringToDisplay + displayDecimalAsString + "k";
    }

    return stringToDisplay + rawCoinValue;
  };

  // console.log("input vars?");
  // console.log(inputVars);

  var vars = { ...inputVars.inputVars };

  var totals = { ...inputVars.totalVals };

  // console.log("vars?");
  // console.log(vars);

  var priceEachFormatted = ics(vars.price, true);
  var priceTotalFormatted = ics(vars.price * vars.quantity, true);

  return (
    <Grid
      display="flex"
      justifyContent={{ xs: "center", sm: "flex-start" }}
      alignItems="center"
      className="invPanel"
    >
      {vars.name == "xxxxxxxxx" ? (
        <Box>
          <Grid
            display="flex"
            justifyContent={{ xs: "center", sm: "flex-start" }}
          >
            <img
              src={
                vars.name == "xxxxxxxxx"
                  ? "./items/coins.png"
                  : "./items/" + vars.name + ".png"
              }
              width="50px"
              height="50px"
              className="anIcon"
            />
            <Stack direction={{ xs: "row", sm: "column" }} spacing={0}>
              <Typography variant="h6">{"Financials"}</Typography>
              <InputLabel>{"Inv: " + totals.inv}</InputLabel>
              <InputLabel>{"Coins: " + totals.coins}</InputLabel>
              <InputLabel>{"Total: " + totals.total}</InputLabel>
            </Stack>
          </Grid>
        </Box>
      ) : (
        <Box className="invBox">
          <Grid
            display="flex"
            justifyContent={{ xs: "center", sm: "flex-start" }}
            visibility={vars.name == "xxxxxxxxx" ? "hidden" : "visible"}
          >
            <img
              src={
                vars.name == "xxxxxxxxx"
                  ? "./items/blank.png"
                  : "./items/" + vars.name + ".png"
              }
              width="50px"
              height="50px"
              className="anIcon"
            />
            <Stack direction={{ xs: "row", sm: "column" }} spacing={0}>
              <Typography variant="h6" color="#A7FFC7">
                {vars.name}
              </Typography>
              <InputLabel>{"Quantity: " + vars.quantity}</InputLabel>
              <InputLabel>{"Sell Ea: " + priceEachFormatted}</InputLabel>
              <InputLabel>{"Sell Total: " + priceTotalFormatted}</InputLabel>
            </Stack>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};
