import { Box, Grid } from "@mui/material";
import { textAlign } from "@mui/system";
import {playershopslogApi} from "../apis/playershopslog"
import "./activitylog.css";
import useAxios from "../hooks/useAxios";
import { Rings } from "react-loader-spinner";
import { useEffect } from "react";
import { ics } from "../utils/utils";

export const PlayerShopsLog = (name) => {

    const [pShopsLogRetrieved, error, loading, axiosFetch] = useAxios();



  

    const sortPShopsLog = (theLogArray) => {
       /// console.log("theLogArray")
       // console.log(theLogArray)


        var theLogs = []

        var eachString = ""

        for(var f = 0; f < theLogArray.length; f++){

            eachString = ""

            if(theLogArray[f].quantity > 1){
                eachString = " each"
            }
            theLogs.push([theLogArray[f].buyer, "bought", theLogArray[f].quantity,"x", theLogArray[f].itemName, "for",ics(theLogArray[f].pricePer, true), eachString, "from", theLogArray[f].seller  ] )
        }


        return theLogs
    }

    const renderFullLog = (theLog) => {
        return (
            <Box>
             {theLog.map((logLine) => (
              <p className={'logItem'}><a href={"/#/spy?u="+logLine[0]}>{logLine[0]}</a>{" "+logLine[1]+" "+logLine[2]+logLine[3]+" "+logLine[4]+" "+logLine[5]+" "+logLine[6]+logLine[7]+" "+logLine[8]+" "}<a href={"/#/spy?u="+logLine[9]}>{logLine[9]}</a></p>
            ))}
                </Box>
        )
    }

    const getData = () => {
        axiosFetch({
          axiosInstance: playershopslogApi,
          method: "get",
        });
      };

      useEffect(() => {
        // action on update of movies
      }, [loading]);

      useEffect(() => {
        getData();
      }, []);

  return (
    !loading?
    (<Box className="act">

       {renderFullLog(sortPShopsLog(pShopsLogRetrieved))}
    </Box>) : 
    (<Rings
    height="180"
    width="180"
    radius="6"
    color="grey"
    ariaLabel="loading"
  />)
  );
};
