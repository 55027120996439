import {
    Box,
    Grid,
    IconButton,
    Link,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import logo from "../../static/favicon.png";
  import { useLocation } from "react-router-dom";
  import useAxios from "../hooks/useAxios";
  import { links } from "./links";
  import { paydayApi } from "../apis/payday";
  import "./Payday.css"
  
  interface Props {
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  }
  
  export default function Payday({ setSelectedIndex }: Props) {
    const { pathname } = useLocation();
  
    const [allPrices, error, loading, axiosFetch] = useAxios();
  
    useEffect(() => {
      setSelectedIndex(-1);
    }, [setSelectedIndex]);
  
    useEffect(() => {
      document.title = process.env.REACT_APP_NAME!;
    }, [pathname]);
  
    const getData = () => {
      axiosFetch({
        axiosInstance: paydayApi,
        method: "get",
      });
    };
  
    // setTimeout(getData, 30000)

    var parsePrices = (pricesResponse) => {
       if(!pricesResponse){
           return 0
       } else {
           var truePrice = pricesResponse-1;
           if(truePrice < 0){
            truePrice = 0
           }
           return truePrice
       }
    }
    var parsedPrices = parsePrices(allPrices)

    var paydayClass = "pdgreen"

    if(parsedPrices >= 78){
        paydayClass = "pdorange"
    }
    if(parsedPrices >= 88){
        paydayClass = "pdred"
    }

    if(parsedPrices >= 95){

        paydayClass = "pdredder"
    }
  
    useEffect(() => {
      getData();
      const interval = setInterval(() => getData(), 20000);
      return () => {
        clearInterval(interval);
      };
     }, []);
  


    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
      >

              <Box className={paydayClass}>{"Payday: " + parsedPrices}</Box>
            
      </Grid>
    );
  }
  