import { Box, Grid, Input, Stack, Typography } from "@mui/material";
import "./inv.css";
import "./statspanel.css";

import { Rings } from "react-loader-spinner";
import useAxios from "../hooks/useAxios";
import { useEffect, useState } from "react";
import { UiControls } from "./uicontrols";
import ChatComponent from "./chat"
import { commandhistorylookupApi } from "../apis/commandhistorylookup";


// const ChatHistoryObject 
const commandHistoryList = [
    {
        username: 'holybova',
        direction: 'in',
        body: "@holybova mulch",
        streamId: 'irm_tv',
        resetCreatedAt: 14,
        timestamp: 'Fri Apr 19 2024 04:09:48 UTC',
        timestampFormatted: '04:09'
    },
    {
        username: 'holybova',
        direction: 'out',
        body: "@holybova mulch",
        streamId: 'irm_tv',
        resetCreatedAt: 14,
        timestamp: 'Fri Apr 19 2024 04:09:48 UTC',
        timestampFormatted: '04:09'
    },


]


export const CommandHistory = (incomingObj) => {

    var username = incomingObj.username

    const [commandHistoryRes, error, loading, axiosFetch] = useAxios();

    var [ssearch, setSsearch] = useState('')

    var [statsState, setStatsState] = useState('closed');


    const handleExpand = (whatIs) => {
        console.log('handleExpand ' + whatIs)
        setStatsState(whatIs)
    }


    const handleRefresh = (whatIs) => {
        console.log('handleRefresh')
        getData()
    }

    //console.log("PLAYER STATS!!!!!!!!!")
    //console.log(playerStatsRetrieved)
    const getData = () => {
        axiosFetch({
            axiosInstance: commandhistorylookupApi(username),
            method: "get",
        });
    };

    useEffect(() => {
        // action on update of movies
    }, [loading]);

    useEffect(() => {
        getData();
    }, []);

    if (statsState == "open") {
        if (loading) {
            return (
                <Rings
                    height="180"
                    width="180"
                    radius="6"
                    color="grey"
                    ariaLabel="loading"
                />
            );
        } else {
            //var quantString = "";
            return (
                <Box className="inv">
                    <Box className="invTitleHolder">
                        <Stack>
                            <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
                                <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
                                    Command History:
                                </Typography>
                                <UiControls expand={handleExpand} refresh={handleRefresh} />
                            </Grid>
                        </Stack>
                    </Box>
                    <Grid
                        display="flex"
                        justifyContent={{ xs: "center", sm: "flex-start" }}
                        alignItems="center"
                    >
                        {!loading ? (
                            <ChatComponent messages={commandHistoryRes}></ChatComponent>
                        ) : (
                            <Box className="loady">
                                <Rings
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="grey"
                                    ariaLabel="loading"
                                />
                            </Box>
                        )}
                    </Grid>
                </Box>
            );
        }
    } else {





        return (
            <Box className="inv">
                <Box className="invTitleHolder">
                    <Stack>
                        <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
                            <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
                                Command History:
                            </Typography>
                            <UiControls expand={handleExpand} refresh={handleRefresh} />
                        </Grid>
                    </Stack>
                </Box>
            </Box>
        );
    }
};