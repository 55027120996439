import { Box, Grid, Input, Stack, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import "./inv.css";
import "./statspanel.css";
import "./uicontrols.css";

import useAxios from "../hooks/useAxios";
import { useEffect, useState } from "react";

export const UiControls = (handlers) => {



  var hideRefresh = handlers.hideRefresh || false

  var [uistater, setUistater] = useState('closed')

  if(handlers.startState){
    uistater = handlers.startState
  }

    var click_open = () => {
     if(uistater == "open"){
      setUistater("closed")
      handlers.expand("closed")
     }else {
      setUistater("open")
      handlers.expand("open")
     }
    }

    var click_refresh = () => {
      handlers.refresh()
    }

    var buttonImgName = "ui_"+uistater

    if(hideRefresh){
      return (
        <Box className="containerr">
          <Box><img className={'uiButton'} src={'./ui_refresh_hidden.png'}/></Box>   <Box onClick={click_open}><img className={'uiButton'} src={'./'+buttonImgName+'.png'}/></Box> 
        </Box>
      );
    }else {
      return (
        <Box className="containerr">
          <Box><img onClick={click_refresh} className={'uiButton'} src={'./ui_refresh.png'}/></Box>   <Box onClick={click_open}><img className={'uiButton'} src={'./'+buttonImgName+'.png'}/></Box> 
        </Box>
      );
    }


   
  }