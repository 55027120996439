import React, { useState, useEffect } from 'react';

const Slideshow = ({ slides, interval }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [visible, setVisible] = useState(true);


  function fadeOut(element) {
    let opacity = 1;
    const intervalId = setInterval(() => {
      opacity -= 0.25; // Decrease opacity by 0.1 in each interval
      element.style.opacity = opacity;
      if (opacity <= 0) {
        clearInterval(intervalId); // Clear interval when opacity reaches 0
      }
    }, 25); // Interval duration: 100 milliseconds (adjust as needed)
  }
  
  // Example usage:
  var myDiv = document.getElementById('theSlider');

  function fadeIn(element) {
    let opacity = 0;
    const intervalId = setInterval(() => {
      opacity += 0.25; // Increase opacity by 0.1 in each interval
      element.style.opacity = opacity;
      if (opacity >= 1) {
        clearInterval(intervalId); // Clear interval when opacity reaches 1
      }
    }, 25); // Interval duration: 100 milliseconds (adjust as needed)
  }

  useEffect(() => {
    const slideShowInterval = setInterval(() => {
        myDiv = document.getElementById('theSlider');
      //setVisible(false); // Hide the current slide
      setTimeout(() => {
        setCurrentSlideIndex(prevIndex =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        );
        setVisible(true); // Show the next slide after 500ms
        fadeIn(myDiv)
      }, 600);
   
      fadeOut(myDiv);   
    }, interval);

    return () => clearInterval(slideShowInterval);
  }, [slides, interval]);

  return (
    <div className="slideshow-container" id="theSlider">
      {slides.map((slide, index) => (
        <div
          key={index}
          style={{
            display: index === currentSlideIndex && visible ? 'block' : 'none',
            transition: 'opacity 0.5s ease-in-out',
            opacity: visible ? 1 : 0, // Fade in/out effect
          }}
        >
          {slide}
        </div>
      ))}
    </div>
  );
};

export default Slideshow;