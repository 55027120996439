import { TWITCH_EMOJIS_CONFIG } from "./TWITCH_EMOJIS_CONFIG";

export const ics = function (rawCoinValue, removeIRM) {
  var stringToDisplay = "$IRM ";
  if (removeIRM === true) {
    stringToDisplay = "";
  }
  var extraDisplayRule = "NONE";

  var displayDecimalAsString = null;

  if (rawCoinValue > 1000000000) {
    displayDecimalAsString = parseFloat(
      ((1.0 * rawCoinValue) / 1000000000).toFixed(4)
    );
    return stringToDisplay + displayDecimalAsString + "b";
  }
  if (rawCoinValue > 1000000) {
    displayDecimalAsString = parseFloat(
      ((1.0 * rawCoinValue) / 1000000).toFixed(2)
    );
    return stringToDisplay + displayDecimalAsString + "m";
  }
  if (rawCoinValue > 10000) {
    displayDecimalAsString = parseFloat(
      ((1.0 * rawCoinValue) / 1000).toFixed(1)
    );
    return stringToDisplay + displayDecimalAsString + "k";
  }

  return stringToDisplay + rawCoinValue;
};

export const r13 = (str) => {
  str = str.toUpperCase();
  return str.replace(/[A-Z]/g, rr13);

  function rr13(correspondance) {
    const charCode = correspondance.charCodeAt();
    //A = 65, Z = 90
    return String.fromCharCode(
      charCode + 13 <= 90 ? charCode + 13 : ((charCode + 13) % 90) + 64
    );
  }
}

export const removeTwitchEmojisFromString = (messageObject) => {
  var sanitisedString = messageObject.body
  TWITCH_EMOJIS_CONFIG.forEach(emoji => {
      if (sanitisedString.includes(emoji)) {
        sanitisedString = sanitisedString.replace(emoji, "");
      }
    }
  )

  if (messageObject.direction == "in") {
    sanitisedString = "!" + sanitisedString
  }
  return sanitisedString
}


