import { useState } from "react";

const useAxios = () => {
  const [response, setResponse] = useState<any>([]);
  const [error, setError] = useState<string | undefined>("");
  const [loading, setLoading] = useState<boolean>(false);

  const axiosFetch = async (configObj: any) => {
    const { axiosInstance, method, url, requestConfig = {} } = configObj;

    try {
      setLoading(true);
      const res = await axiosInstance[method.toLowerCase()](url, {
        ...requestConfig,
      });
      // FOR DEV ONLY
      await delay();

      // console.log(res);
      setResponse(res.data);
    } catch (err) {
      // console.log(err);
      // setError(err);
    } finally {
      setLoading(false);
    }
  };
  return [response, error, loading, axiosFetch];
};

const delay = () => {
  return new Promise((resolve) => setTimeout(resolve, 1500));
};

export default useAxios;
