import {
    Box,
    Grid,
    IconButton,
    Link,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import logo from "../../static/favicon.png";
  import { useLocation } from "react-router-dom";
  import useAxios from "../hooks/useAxios";
  import { links } from "./links";
  import { pricesApi } from "../apis/prices";
  import "./Wiki.css"
  
  interface Props {
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  }
  
  export default function Wiki({ setSelectedIndex }: Props) {
    const { pathname } = useLocation();
  
    const [allPrices, error, loading, axiosFetch] = useAxios();
  
    useEffect(() => {
      setSelectedIndex(-1);
    }, [setSelectedIndex]);
  
    useEffect(() => {
      document.title = process.env.REACT_APP_NAME!;
    }, [pathname]);
  

  
    // setTimeout(getData, 30000)
  ;
  


    return (
     <div className="wikiOuterDiv">
        <iframe className="wikiInnerDiv" src="https://fishbotapp.github.io/fishbotwiki/" ></iframe>
     </div>
    );
  }
  