import { Box, Grid } from "@mui/material";
import { textAlign } from "@mui/system";
import {activityLogApi} from "../apis/activitylog"
import "./activitylog.css";
import useAxios from "../hooks/useAxios";
import { Rings } from "react-loader-spinner";
import { useEffect } from "react";

export const ActivityLog = (name) => {

    const [activityLogRetrieved, error, loading, axiosFetch] = useAxios();


    const itemNameToImage = (itemName) => {
        return (<img src={'items/'+itemName+'.png'} />)
    }

    const removeDuplicatesFromLog = (logArray) => {
        var outputArray = []
        var counts = {

        }
        for(var g = 0; g < logArray.length; g++){
            if(logArray[g][0] !== null && logArray[g][0] !== undefined){
                if(counts[logArray[g][0]]){
                    counts[logArray[g][0]]++
                } else {
                    counts[logArray[g][0]] = 1
                }
                if(counts[logArray[g][0]] <= 3){
                    outputArray.push(logArray[g])
                }
            }
        }

        var max10Array = []
        for(var g = 0; g < 10 && g < outputArray.length; g++){
            max10Array.push(outputArray[g])
        }
        return max10Array
    }

    const sortActivityLogIntoString = (theLogArray) => {
        var tempA = null
        var fullLogAsString = []
        var tempString = ""
        var itemArray = []
        for(var d = 0; d < theLogArray.length; d++){
            tempA = theLogArray[d]
            tempString = ""
            itemArray = []

            if(tempA && tempA.a.type && tempA.a.type == "fish"){
                tempString += " returned from a "
                tempString += tempA.a.length + " minute " + tempA.a.trip.toUpperCase() + " fish with a " + tempA.a.tool + " "

                // add all items
                for(var f = 0; f < tempA.r.items.length; f++){
                    if(tempA.r.items[f][1] !== 0 && tempA.r.items[f][1] !== null){
                        itemArray.push(tempA.r.items[f])
                    }
                }

                if(itemArray.length == 0){
                    tempString += "and came home empty handed. Gained " + tempA.r.exp.fish + " Fish exp."
                } else {
                    tempString += "and got: "
                    for(var c = 0; c < itemArray.length; c++){
                        tempString += itemArray[c][1] + "x " + itemArray[c][0]
                        if(c < itemArray.length-1){
                            tempString += " "
                        }
                    }

                    tempString += ". Gained " + tempA.r.exp.fish + " Fish exp."
                }
                fullLogAsString.push([tempA.u,tempString])
            }

            if(tempA && tempA.a.type && tempA.a.type == "thieve"){
                tempString += " thieved the " + tempA.a.location + " with their " + tempA.a.tool + " "

                if(tempA.r.gold > 0){
                    itemArray.push(["gold", tempA.r.gold])
                }

                // add all items
                for(var f = 0; f < tempA.r.items.length; f++){
                    if(tempA.r.items[f][1] !== 0 && tempA.r.items[f][1] !== null){
                        itemArray.push(tempA.r.items[f])
                    }
                }

                if(itemArray.length == 0){
                    tempString += "and came home empty handed. Gained " + tempA.r.exp.thieve + " Thieve exp."
                } else {
                    tempString += "and got: "
                    for(var c = 0; c < itemArray.length; c++){

                        tempString += itemArray[c][1] + "x " + itemArray[c][0]
                        if(c < itemArray.length-1){
                            tempString += " "
                        }
                    }
                    tempString += ". Gained " + tempA.r.exp.thieve + " Thieve exp."
                }
                fullLogAsString.push([tempA.u,tempString])
            }

        }

        return fullLogAsString
    }

    const renderFullLog = (theLog) => {
        return (
            <Box>
             {theLog.map((logLine) => (
              <p className={'logItem'}><a href={"/#/spy?u="+logLine[0]}>{logLine[0]}</a>{logLine[1]}</p>
            ))}
                </Box>
        )
    }

    const getData = () => {
        axiosFetch({
          axiosInstance: activityLogApi,
          method: "get",
        });
      };

      useEffect(() => {
        // action on update of movies
      }, [loading]);

      useEffect(() => {
        getData();
      }, []);

  return (
    !loading?
    (<Box className="act">

       {renderFullLog(removeDuplicatesFromLog(sortActivityLogIntoString(activityLogRetrieved)))}
    </Box>) : 
    (<Rings
    height="180"
    width="180"
    radius="6"
    color="grey"
    ariaLabel="loading"
  />)
  );
};
