import { Box, Grid, Stack, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { InvItem } from "./invItem";
import "./allListings.css";
import { ItemDetails } from "./itemDetails";
import { useState } from "react";
import { SellingListing } from "./sellingListing";
import { PlayerShopsRow } from "./playershopsrow";

export const AllListingsAsRows = (allListings) => {
 
  var j = []
 
  if (!allListings || !allListings.theData) {
 
  } else {
    
j = allListings.theData
  }




  // fake api return

  /*

    listingType: string;
    listingId: any;
    itemName: any;
    username: any;
    pricePer: any;
    pricePerFormatted: string;
    stock: any;
    createdAt: any;
    quality: any;

    */

  return (
    <Box className="allListingsContainer">
      <Grid container spacing={{ xs: 0, md: 0 }}>

      <Box padding={"6px"} className={"theContainer"}>
              <PlayerShopsRow
              itemName={"HEADER"}
              />
            </Box>

        {j.map((_, index) => {
          // console.log(_);

          
          return (
            <Box padding={"6px"} className={"theContainer"}>
              <PlayerShopsRow
                itemName={_.itemName.toString()}
                stock={_.stock.toString()}
                sellerUsername={_.username.toString()}
                priceEach={_.pricePerFormatted}
                itemQuality={_.quality || "white"}
              />
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};
