import axios from "axios";
const API_BASE_URL = "https://fishbot-backend.herokuapp.com/";

export const colHighscoresApi = axios.create({
  baseURL: API_BASE_URL + "topcollectionlog",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
