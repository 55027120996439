import { Box, Grid } from "@mui/material";
import { textAlign } from "@mui/system";
import "./col.css";

export const ColItem = (name) => {
  // console.log("name");
  // console.log(name);

  var foundName = name.foundName;

  if (foundName == null) {
    foundName = "blank";
  }

  return (
    <Box className="gridItemHolder">
      {/* <Box className="numOverlay2">
      </Box>
      <Box className="numOverlay">
      </Box>
      */}
      <Box className="anItemBoxClaimed">
        <img src={"./items/" + foundName + ".png"} />
      </Box>
      <Box className="anItemBox">
        <img
          className="colItemImage"
          src={"./collectionlog/" + name.name + ".png"}
        />
      </Box>
    </Box>
  );
};
