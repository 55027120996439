import { Box, Grid, Stack, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { ColItem } from "./colItem";
import "./col.css";
import { useState } from "react";
import { r13 } from "../utils/utils";
import { UiControls } from "./uicontrols";

export const CollectionLog = (itemList, quantity) => {
  if (!itemList) {
    itemList = [];
  }
  //   console.log(itemList);
  var j = itemList.itemList;
  //   console.log(j);

  var whichView = itemList.view

  var [iteminfo, setIteminfo] = useState({
    name: "xxxxxxxxx",
  });

  var foundLog = itemList.foundLog;

  //   console.log("##################foundlog?");
  //   console.log(foundLog);

  var foundTotal = 0;

  for (var f = 0; f < foundLog.length; f++) {
    if (foundLog[f] !== null) {
      foundTotal++;
    }
  }


  var [statsState, setStatsState] = useState('closed');


  const handleExpand = (whatIs) => {
    console.log('handleExpand ' + whatIs)
    setStatsState(whatIs)
  }

  var getStringFromIndex = (theIndex) => {
    var newIndex = theIndex+1
    if(newIndex <= 9){
      return "#000"+newIndex
    }
    if(newIndex <= 99){
      return "#00"+newIndex
    }
    if(newIndex <= 999){
      return "#0"+newIndex
    }
    if(newIndex <= 9999){
      return "#"+newIndex
    }
  }


  if(statsState == 'closed'){
    return (
      <Box className="inv">
        <Box className="invTitleHolder">
          <Grid container className={'spanRowT quava'} spacing={{ xs: 0, md: 0 }}>
            <Typography textAlign={"center"} variant="h5" className={'title80'}>
              Collection Log
            </Typography>
            <Typography textAlign={"center"} className="colFoundText">
              {foundTotal} of {j.length}
            </Typography>
            <UiControls hideRefresh={true} startState={statsState} expand={handleExpand} />
              </Grid>
            {/* <ItemDetails inputVars={iteminfo} totalVals={itemList.totals} /> */}
        </Box>
        <Grid container spacing={{ xs: 4, md: 0 }} className={'juicer'}>
          
          </Grid>
      </Box>
    );

  }


  if(whichView === 0){
  return (
    <Box className="inv">
      <Box className="invTitleHolder">
        <Grid container className={'spanRowT quava'} spacing={{ xs: 0 }}>
          <Typography textAlign={"center"} variant="h5" className={'title80'}>
            Collection Log
          </Typography>
          <Typography textAlign={"center"} className="colFoundText">
            {foundTotal} of {j.length}
          </Typography>
          <UiControls hideRefresh={true} startState={statsState} expand={handleExpand} />
            </Grid>
          {/* <ItemDetails inputVars={iteminfo} totalVals={itemList.totals} /> */}
      </Box>
      <Grid container spacing={{ xs: 0, md: 0 }}>
        {j.map((_, index) => {
          //   console.log(_);
          return (
            <Box padding={"6px"}>
              <ColItem name={_.toString()} foundName={foundLog[index]} />
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
} else {
  return (
  <Box className="inv">
        <Grid container className={'spanRowT quava'} spacing={{ xs: 0 }}>
          <Typography textAlign={"center"} variant="h5" className={'title80'}>
            Collection Log
          </Typography>
          <Typography textAlign={"center"} className="colFoundText">
            {foundTotal} of {j.length}
          </Typography>
          <UiControls hideRefresh={true} startState={statsState} expand={handleExpand} />
            </Grid>
  <Grid container spacing={{ xs: 4, md: 0 }}>
    {j.map((_, index) => {
      //   console.log(_);

      if(foundLog[index] !== null){
        return (
          <Box padding={"2px"} className={"aColLogLista"}>
            {getStringFromIndex(index)+" "+r13(_.toString()).toLowerCase()}
          </Box>
        );
      }else {
        return (
          <Box padding={"2px"} className={"aColLogLista graya"}>
          {getStringFromIndex(index)+" ??????????"}
        </Box>
        );
      }


    })}
  </Grid>
</Box>
  )
}
};
