import {
    Box,
    Grid,
    IconButton,
    Link,
    Stack,
    TextField,
    Tooltip,
    Input,
    Typography,
    InputLabel,
    Button,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useLocation, useParams } from "react-router-dom";
  import useAxios from "../hooks/useAxios";
  import { coinHighscoresApi } from "../apis/coin-highscores";
  import { playerLookupApi } from "../apis/player-lookup";
  import { TableComponent } from "../components/table";
  import { captureRejectionSymbol } from "events";
  import ReactSkillbar from "react-skillbars";
  import { Rings } from "react-loader-spinner";
  import ProgressBar from "@ramonak/react-progress-bar";
  import queryString from 'query-string';
  import "./spy.css";
  import "./PlayershopsWidget.css";
  import { itemsHashed } from "../../const/const";
  import { Inv } from "../components/inv";
  import { CollectionLog } from "../components/collectionlog";
  import { playershopsApi } from "../apis/playershops"
import { AllListings } from "../components/allListings";
  type userData = {
    username: any;
    coins: any;
    team: any;
    fishing: any;
    thieving: any;
    crafting: any;
    inv: any;
    coinsFormatted: any;
  };
  
  interface Props {
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  }
  
  // https://fishbot-backend.herokuapp.com/api/playershops
  
  export default function Playershopswidget({ setSelectedIndex }: Props) {
  
    const [allPrices, error, loading, axiosFetch] = useAxios();
  
    useEffect(() => {
      setSelectedIndex(-1);
    }, [setSelectedIndex]);
  
    //console.log("whats allprices?")
    //console.log(allPrices)
  
    const getData = () => {
      axiosFetch({
        axiosInstance: playershopsApi,
        method: "get",
      });
    };
  

    useEffect(() => {
      getData();
      const interval = setInterval(() => getData(), 20000);
      return () => {
        clearInterval(interval);
      };
     }, []);

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        // alignItems="center"
        // justifyContent="center"
        sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
      >
        <Grid item xs={1}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Box>
              {/* <Typography variant="h3">Playershops</Typography>
              <Typography variant="h6">
                Items for sale from the playershops 
              </Typography> */}
              <Box>
                <AllListings theData={allPrices} />
              </Box>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    );
  }
  