import { textAlign } from "@mui/system";

export const TableComponent = ({ data }) => {
  let headings = [];
  if (data[1]) {
    headings = Object.keys(data[1]);
  }
  //console.log(data)
  return (
    <table className="table table-bordered tableX">
      <thead>
        <tr>
          {headings.map((heading) => (
            <th>
              {/* <p>{heading}</p> */}
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr>
            {headings.map((heading) => (
              <td>{heading == 'Player'? <a href={"/#/spy?u="+item[heading]}>{item[heading]}</a> : item[heading]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
