import { Box, Grid } from "@mui/material";
import { textAlign } from "@mui/system";
import "./inv.css";

export const InvItem = (name, quantity) => {
  // console.log("name");
  // console.log(name);
  var quantString = "";

  var hoverCallBack = function () {
    var returnObj = {
      name: name.name,
      quantity: name.quantity,
      price: name.price,
    };
    // console.log("we hovered on");
    // console.log(returnObj);
    name.hoverCallBack(returnObj);
  };
  var hoverEndCallBack = function () {
    name.hoverEndCallBack();
  };

  var ics = function (rawCoinValue, removeIRM) {
    var stringToDisplay = "$IRM ";
    if (removeIRM === true) {
      stringToDisplay = "";
    }
    var extraDisplayRule = "NONE";

    var displayDecimalAsString = null;

    if (rawCoinValue > 1000000000) {
      displayDecimalAsString = parseFloat(
        ((1.0 * rawCoinValue) / 1000000000).toFixed(4)
      );
      return stringToDisplay + displayDecimalAsString + "b";
    }
    if (rawCoinValue > 1000000) {
      displayDecimalAsString = parseFloat(
        ((1.0 * rawCoinValue) / 1000000).toFixed(2)
      );
      return stringToDisplay + displayDecimalAsString + "m";
    }
    if (rawCoinValue > 10000) {
      displayDecimalAsString = parseFloat(
        ((1.0 * rawCoinValue) / 1000).toFixed(1)
      );
      return stringToDisplay + displayDecimalAsString + "k";
    }

    return stringToDisplay + rawCoinValue;
  };

  if (name.quantity > 1) {
    quantString = "x" + ics(name.quantity, true);
  }
  // console.log(name.quantity);
  return (
    <Box
      onMouseEnter={hoverCallBack}
      onMouseLeave={hoverEndCallBack}
      className="gridItemHolder"
    >
      <Box className="numOverlay2">
        <p className="pp">{quantString}</p>
      </Box>
      <Box className="numOverlay">
        <p className="pp">{quantString}</p>
      </Box>
      <Box className="anItemBox">
        <img src={"./items/" + name.name + ".png"} />
      </Box>
    </Box>
  );
};
