import {
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Input,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import { coinHighscoresApi } from "../apis/coin-highscores";
import { playerLookupApi } from "../apis/player-lookup";
import { TableComponent } from "../components/table";
import ReactSkillbar from "react-skillbars";
import { Rings } from "react-loader-spinner";
import ProgressBar from "@ramonak/react-progress-bar";
import queryString from 'query-string';
import "./spy.css";
import { itemsHashed } from "../../const/const";
import { Inv } from "../components/inv";
import { CollectionLog } from "../components/collectionlog";
import { playershopsApi } from "../apis/playershops"
import { AllListings } from "../components/allListings";
import { AllListingsAsRows } from "../components/allListingsAsRows";
import axios from "axios";
type userData = {
  username: any;
  coins: any;
  team: any;
  fishing: any;
  thieving: any;
  crafting: any;
  inv: any;
  coinsFormatted: any;
};

interface Props {
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

// https://fishbot-backend.herokuapp.com/api/playershops

export default class Playershops extends Component {




        state = {
          currentFilter: '',
          apiResults: [],
          currentSelected: 0,
          ogResults: []
        };

        constructor(props) {
          super(props);
          this.handleTextChange = this.handleTextChange.bind(this);
        }

        componentDidMount() {
          this.getPShopsData();
        }


       getPShopsData = async () => {


          var theResults = await (await axios.get('https://fishbot-backend.herokuapp.com/api/playershops')).data

          this.setState({ apiResults: theResults,ogResults: [...theResults] })

        

        }

       allOrders = ["Ordred by Date","Ordered by Price","Ordered by Item Name","Ordered by Seller Name","Ordered by Stock" ]


      handleTextChange = (e) => {
        this.setState({
          currentFilter: e.target.value,
        })
      }

      onButtonClicka = (e) => {
        var currentSelected = this.state.currentSelected
        currentSelected++
        if(currentSelected >= 5){
          currentSelected = 0
        }
        this.setState({
          currentSelected:currentSelected
        })
      }

      sortAlphabet(theArr) {
        return  theArr.sort((a, b) => a.itemName.localeCompare(b.itemName))
    }
    sortName(theArr) {
      return  theArr.sort((a, b) => a.username.localeCompare(b.username))
  }
  sortStock(theArr) {
    return theArr.sort((a, b) => (b.stock - a.stock))
}
sortPrice(theArr) {
  //console.log(theArr)
  return theArr.sort((a, b) => (b.pricePer - a.pricePer))
}

      flilterByDateF = (theArray) => theArray
      filterByPrice  = (theArray) => this.sortPrice(theArray)
      filterByItemName = (theArray) => this.sortAlphabet(theArray)
      filterBySellerName = (theArray) => this.sortName(theArray)
      filterByStock = (theArray) => this.sortStock(theArray)
   

      finalFilter = (theArray) => {
       // console.log("whats theArray?")
       // console.log(theArray)
        if(this.state.currentSelected == 1){
          return this.filterByPrice(theArray)
        }
        if(this.state.currentSelected == 2){
          return this.filterByItemName(theArray)
        }
        if(this.state.currentSelected == 3){
          return this.filterBySellerName(theArray)
        }
        if(this.state.currentSelected == 4){
          return this.filterByStock(theArray)
        }
        return this.state.ogResults
      }


      render() {
        var filteredPrices = []

        if(this.state.currentFilter == ''){
          filteredPrices = this.state.apiResults || []
        }else {
        for(var d = 0; d < this.state.apiResults.length; d++){
          if(this.state.apiResults[d].itemName.includes(this.state.currentFilter) || this.state.apiResults[d].username.includes(this.state.currentFilter)){
            filteredPrices.push({...this.state.apiResults[d]})
          }
        }
      }



              return (
                <Box >
                  <Box className={"pageholder2"}>
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      // alignItems="center"
                      // justifyContent="center"
                      sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
                    >
                      <Grid item xs={1}>
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                          <Box>
                            <Typography variant="h3">Playershops</Typography>
                            <Typography variant="h6">
                              Items for sale from the playershops
                            </Typography>
                            <Box>
                              <InputLabel>Search:</InputLabel>
                              <Input
                                type="text"
                                id="inputSpyName"
                                className="inputSpyName"
                                // defaultValue={""}
                                onChange={this.handleTextChange}
                              // value={usernamey}
                              />
                            </Box>
                            <Box>
                              <Button onClick={this.onButtonClicka}>{this.allOrders[this.state.currentSelected]}</Button>
                            </Box>
                            <Box className={"sproo"}>
                              <AllListingsAsRows theData={this.finalFilter(filteredPrices)} />
                            </Box>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              );
      }
}
