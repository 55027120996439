import React from 'react';
import './chat.css'
import { removeTwitchEmojisFromString } from '../utils/utils';

interface Message {
  username: string;
  direction: string;
  body: string;
  streamId: string;
  resetCreatedAt: number;
  timestamp: string;
  timestampFormatted: string;
}

interface Props {
  messages: Message[];
}

const ChatComponent: React.FC<Props> = ({ messages }) => {
  const reversedMessages = [...messages].reverse()
  const filteredMessages = reversedMessages.filter(({ body }) => !body.includes('(cooldown:'))

  const getColorForMessage = (direction: string): string => {
    // Add your logic here to determine the color based on the sender string
    if (direction === "out") {
      return "#A8E887"; // Red color for user1
    } else {
      return "#87E5E8"; // Green color for user2
    }

  };

  const getUsernameForMessage = (username: string, direction: string): string => {
    if (direction === "out") {
      return "phuryfishbot";
    } else {
      return username;
    }

  };


  var d = () => {
    var objDiv = document.getElementById("myCC");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  setTimeout(d, 100)

  return (
    <div className="chat-container" id="myCC">
      <div className="chat-messages">
        {filteredMessages.map((message) => (
          <div key={message.timestamp} className="chat-message">
            <span className="timestamp">{message.timestampFormatted}</span>
            <span className="sender" style={{ color: getColorForMessage(message.direction) }}>{getUsernameForMessage(message.username, message.direction)}{": "}</span>
            <span className="text">{removeTwitchEmojisFromString(message)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatComponent;