import {
    Box,
    Grid,
    IconButton,
    Link,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import logo from "../../static/favicon.png";
  import { useLocation } from "react-router-dom";
  import useAxios from "../hooks/useAxios";
  import { links } from "./links";
  import { collogApi } from "../apis/collog";
  import "./collectionlogfeed.css"
  
  interface Props {
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  }
  
  export default function CollectionLogFeed({ setSelectedIndex }: Props) {
    const { pathname } = useLocation();
  
    const [allPrices, error, loading, axiosFetch] = useAxios();
  
    useEffect(() => {
      setSelectedIndex(-1);
    }, [setSelectedIndex]);
  
    useEffect(() => {
      document.title = process.env.REACT_APP_NAME!;
    }, [pathname]);
  
    const getData = () => {
      axiosFetch({
        axiosInstance: collogApi,
        method: "get",
      });
    };

    const getClassFromItemRarity = theRarity => {
      if(!theRarity || theRarity == null){
        return "greenRarity"
      }
      if(theRarity == "blue"){
        return "blueRarity"
      }
      if(theRarity == "purple"){
        return "purpleRarity"
      }
      if(theRarity == "gold"){
        return "goldRarity"
      }
      if(theRarity == "pink"){
        return "pinkRarity"
      }
    }
  
    // setTimeout(getData, 30000)

    var parsePrices = (pricesResponse) => {
        if(pricesResponse.length == 0){
            return []
        }
        return pricesResponse
    }

    var parsedPrices = parsePrices(allPrices)
  
    useEffect(() => {
      getData();
      const interval = setInterval(() => getData(), 20000);
      return () => {
        clearInterval(interval);
      };
     }, []);
  


    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
      >
                  {
                  
                  
                  parsedPrices.map((priceLine) => {if(priceLine && priceLine[0] && priceLine[0].length >= 1) {
                    
                    return ( 
              <Box><div className="allText"><img className="anImgOnPrices" src={"./items/"+priceLine[1]+".png"} /><b className={getClassFromItemRarity(priceLine[2])}>{`${priceLine[1]}`}</b><i className="theBy"> by  </i>{` ${priceLine[0]}`}</div></Box>
            )
          }})
            
            
            }
      </Grid>
    );
  }
  