import { Box, Grid, Input, Stack, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import "./inv.css";
import "./statspanel.css";

import { Rings } from "react-loader-spinner";
import { statslookupApi } from "../apis/statslookup";
import useAxios from "../hooks/useAxios";
import { useEffect, useState } from "react";
import { UiControls } from "./uicontrols";

export const StatsPanel = (incomingObj) => {

  var username = incomingObj.username

  const [playerStatsRetrieved, error, loading, axiosFetch] = useAxios();
  
  var [ssearch, setSsearch] = useState('')

  var handleSearchChange = (event) => {

    //  setUsernamey(event.target.value);
    setSsearch(event.target.value)
}

var [statsState, setStatsState] = useState('closed');


const handleExpand = (whatIs) => {
  console.log('handleExpand ' + whatIs)
  setStatsState(whatIs)
}


const handleRefresh = (whatIs) => {
  console.log('handleRefresh')
  getData()
}

//console.log("PLAYER STATS!!!!!!!!!")
//console.log(playerStatsRetrieved)
  const getData = () => {
    axiosFetch({
      axiosInstance: statslookupApi(username),
      method: "get",
    });
  };

  useEffect(() => {
    // action on update of movies
  }, [loading]);

  useEffect(() => {
    getData();
  }, []);

  var trueResults = []
  if(ssearch && ssearch !== ''){
for(var p = 0; p < playerStatsRetrieved.length; p++){
  if(playerStatsRetrieved[p].toLowerCase().includes(ssearch.toLowerCase())){
    trueResults.push(playerStatsRetrieved[p])
  }
}
  } else {
    trueResults = playerStatsRetrieved
  }

  if(statsState == "open"){
  if (loading) {
    return (
      <Rings
        height="180"
        width="180"
        radius="6"
        color="grey"
        ariaLabel="loading"
      />
    );
  } else {
    //var quantString = "";
    return (
      <Box className="inv">
      <Box className="invTitleHolder">
        <Stack>
        <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
          <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
            Current Buffs:
          </Typography>
          <UiControls expand={handleExpand} refresh={handleRefresh}/>
          </Grid>
          <Input
              type="text"
              id="inputStatsFilter"
              className="inputSpyName"
              defaultValue={''}
              onChange={handleSearchChange}
              // value={usernamey}
            />
        </Stack>
      </Box>
      <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
        {trueResults.map((_, index) => {
          // console.log(_);
          return (
            <Grid xs={6} className={'spanRow'}>

              <Box padding={"8px"}>
                {"" + _.toString() + ""}
              </Box>

            </Grid>
          );
        })}
      </Grid>
    </Box>
    );
  }
} else {





  return (
    <Box className="inv">
    <Box className="invTitleHolder">
      <Stack>
      <Grid container className={'spanRowT'} spacing={{ xs: 0 }}>
        <Typography textAlign={"center"} variant="h5" className={"theTitle"}>
          Current Buffs:
        </Typography>
        <UiControls expand={handleExpand} refresh={handleRefresh}/>
        </Grid>
      </Stack>
    </Box>
  </Box>
  );
}
};
