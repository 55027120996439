import {
    Box,
    Grid,
    IconButton,
    Link,
    Stack,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import logo from "../../static/favicon.png";
  import { useLocation } from "react-router-dom";
  import useAxios from "../hooks/useAxios";
  import { links } from "./links";
  import { pricesApi } from "../apis/prices";
  import "./prices.css"
  
  interface Props {
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  }
  
  export default function Prices({ setSelectedIndex }: Props) {
    const { pathname } = useLocation();
  
    const [allPrices, error, loading, axiosFetch] = useAxios();
  
    useEffect(() => {
      setSelectedIndex(-1);
    }, [setSelectedIndex]);
  
    useEffect(() => {
      document.title = process.env.REACT_APP_NAME!;
    }, [pathname]);
  
    const getData = () => {
      axiosFetch({
        axiosInstance: pricesApi,
        method: "get",
      });
    };
  
    // setTimeout(getData, 30000)

    var parsePrices = (pricesResponse) => {
        if(pricesResponse.length == 0){
            return []
        }
        var noTags = pricesResponse.replace(/(<([^>]+)>)/gi, "")
        var array = noTags.split(": ")
        var allPrices = []
        allPrices.push([array[1].toLowerCase(), array[2].split(" ")[0]])
        for(var g = 2; g < array.length-1; g++){

        
        allPrices.push([array[g].split(" ")[1].toLowerCase(), array[g+1].split(" ")[0]])
        }
        return allPrices
    }
    var parsedPrices = parsePrices(allPrices)
  
    useEffect(() => {
      getData();
      const interval = setInterval(() => getData(), 20000);
      return () => {
        clearInterval(interval);
      };
     }, []);
  


    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
      >
                  {parsedPrices.map((priceLine) => (
              <Box><img className="anImgOnPrices" src={"./items/"+priceLine[0]+".png"} />{priceLine[1]}</Box>
            ))}
      </Grid>
    );
  }
  