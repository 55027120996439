import React, { useState } from 'react';
import { Box, Grid } from "@mui/material";
import { textAlign } from "@mui/system";
import "./playershopsrow.css";

export const PlayerShopsRow = (incomingObj) => {
    const { itemName, stock, sellerUsername, priceEach, itemQuality } = incomingObj;
    const [thecopyImageName, setThecopyImageName] = useState("copy")
    if (itemName === "HEADER") {
        return (
            <Grid container className="rowcontainer">
                <Grid item xs={1.2}>
                    <Box className={'brow boldme rowsmall'}>{"Stock:"}</Box>
                </Grid>
                <Grid item xs={4.8}>
                    <Box className={'brow boldme rowmed rowlarge rowitem'}>{"Item:"}</Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={'brow boldme '}>{"Seller:"}</Box>
                </Grid>
                <Grid item xs={2}>
                    <Box className={'brow boldme'}>{"Price Ea:"}</Box>
                </Grid>
                <Grid item xs={1}>
                    <Box className={'brow boldme'}>{"Copy:"}</Box>
                </Grid>
            </Grid>
        );
    }

    const itemColorClassName = "quality_" + (itemQuality || "white");

    let priceColor = "white";

    if (priceEach.includes('k')) {
        priceColor = "rgb(255, 255, 153)";
    } else if (priceEach.includes('m')) {
        priceColor = "rgb(77, 255, 219)";
    } else if (priceEach.includes('b')) {
        priceColor = "rgb(255, 102, 255)";
    }

    let eachString = "";

    if (stock > 1) {
        eachString += " each";
    }


    const doAnim = () => {
        setThecopyImageName("copyconfirm")

        var d = () => {
            setThecopyImageName("copy")
        }

        setTimeout(d,1000)
        
    }
      


     
    
    var textToCopy = "!ps buy 1 " + itemName + " " + sellerUsername

    return (
        <Grid container className="rowcontainer">
            <Grid item xs={1.2}>
                <Box className={'brow rowsmall'}>{stock + "x"}</Box>
            </Grid>
            <Grid item xs={4.8} className={'quality_' + itemQuality + ' brow rowmed rowitem rowlarge'}>
                <img className={'theimg'} src={"./items/" + itemName + ".png"} alt={itemName} />
                {itemName}
            </Grid>
            <Grid item xs={3}>
                <Box className={'brow '}>{sellerUsername}</Box>
            </Grid>
            <Grid item xs={2}>
                <Box className={'brow'} style={{ color: priceColor }}>
                    {priceEach}
                </Box>
            </Grid>
            <Grid item xs={1}>
                    <Box onClick={() => {navigator.clipboard.writeText(textToCopy); doAnim()}} className={'brow boldme'}>{<img className={'theimg'} src={"./"+thecopyImageName+".png"}  />}</Box>
            </Grid>
        </Grid>
    );
};


